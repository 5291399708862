import { Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Chart, { useChart } from '../../../../../components/chart';
import { fNumber } from '../../../../../utils/formatNumbers';
import { useTotalDailySalesQuery } from '../../../../../redux/api/api';
import { useSelector } from 'react-redux';

const useTotalDailySalesSeries = (totalDailySales) => {
  return useMemo(() => {
    if (!totalDailySales) return [];
    return Object.entries(totalDailySales)
      .filter(([key]) => !['total', 'remainingTotal', 'remainingPercent', 'goal'].includes(key)) // Exclude non-date keys
      .map(([date, data]) => ({
        label: date,
        value: data?.totalSales ?? 0, // Use nullish coalescing
      }))
      .filter((item) => typeof item.value === 'number' && !isNaN(item.value)); // Ensure valid numeric values
  }, [totalDailySales]);
};

const SalesThisMonth = () => {
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const { data: totalDailySales } = useTotalDailySalesQuery({ orgId }, { enabled: !!orgId });

  const totalDailySalesSeries = useTotalDailySalesSeries(totalDailySales);
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    setChartSeries(totalDailySalesSeries.map((i) => i.value));
  }, [totalDailySalesSeries]);

  const chartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: { formatter: (val) => fNumber(val) },
    },
    xaxis: {
      categories: totalDailySalesSeries.length > 0
        ? totalDailySalesSeries.map((item) => item.label)
        : ['No Data'], // Default category
    },
    yaxis: {
      labels: {
        formatter: (value) => fNumber(value), // Ensure valid y-axis labels
      },
    },
  });

  return (
    <Paper sx={{ borderRadius: '16px', padding: '1rem', height: '100%' }}>
      <Typography variant="h6">Sales This Month</Typography>
      <Typography variant="h6" color={'text.disabled'}>
        Users from all channels
      </Typography>
      <Stack justifyContent={'flex-start'} mt={'2rem'}>
        <Typography variant="h2" fontWeight={550}>
          <span style={{ fontSize: '20px', color: 'gray', alignSelf: 'flex-start' }}>₹ </span>
          {fNumber(totalDailySales?.total || 0)}
        </Typography>
        <Typography variant="body1" color={'text.disabled'}>
          Another ₹{fNumber(totalDailySales?.remainingTotal || 0)} to Goal
        </Typography>
      </Stack>
      <Stack mt={'2rem'}>
        <Chart
          key={orgId}
          type="line"
          series={chartSeries.length > 0 ? [{ data: chartSeries }] : [{ data: [0] }]} // Fallback for empty series
          options={chartOptions}
          width="100%"
          height={250}
        />
      </Stack>
    </Paper>
  );
};

export default SalesThisMonth;
