import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { openSessionExpiredDialog } from "../slices/appReducer";
import { openMessage } from "../slices/showMessageSlice";

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions);
//   if (result.error && result.error.status === 401) {
//     localStorage.clear();
//     window.location.replace("/");
//   }
//   return result;
// };

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (window.location.pathname !== "/") {
      api.dispatch(openSessionExpiredDialog(true));
      localStorage.clear();
      window.location.replace("/");
    }
    localStorage.clear();
    window.location.replace("/");
  }
  // else if (result.error && (result.error.status === 500 || result.error.status === 400)) {
  //   api.dispatch(openMessage({
  //       message: "Something went wrong!",
  //       messageSeverity: 'error'
  //   }))
  // }
  else if (result.error && result.error.status == "FETCH_ERROR") {
    api.dispatch(
      openMessage({
        message: "Service not available!",
        messageSeverity: "error",
      })
    );
  }
  return result;
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ENDPOINT_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().auth.token?.access_token;
    if (true) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Access-Control-Allow-Origin", "*");
    }
    return headers;
  },
});

export const horecaApi = createApi({
  reducerPath: "horecaApi",
  tagTypes: [
    "save-organization",
    "add-new-org",
    "order-updated",
    "order-status",
    "private-vendor-added",
    "payment-status",
    "org-updated",
    "useradded",
    "selectedAssignUser",
    "changeOrgStatus",
    "roles",
    "scheduled-order-changed",
    "to-do-done",
    "new-org-addded",
    "user-profile-updated",
    "ratings-added",
    "org-logo-updated",
    "changeUserStatus",
    "new-address",
    "item-removed",
    "new-item-added",
    "private-vendor-items",
    "latest-sub-categories",
    "latest-categories"
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getOtp: builder.mutation({
      query: (payload) => {
        return {
          url: `/horeca1_backend/auth/otp`,
          method: "POST",
          body: payload,
        };
      },
    }),
    login: builder.mutation({
      query: (payload) => {
        return {
          url: `/horeca1_backend/auth/login`,
          method: "POST",
          body: payload,
        };
      },
    }),
    signup: builder.mutation({
      query: (payload) => {
        return {
          url: "/horeca1_backend/auth/signup",
          method: "POST",
          body: payload,
        };
      },
    }),
    getRoles: builder.query({
      query: () => {
        return {
          url: "/horeca1_backend/auth/signup-roles",
        };
      },
    }),
    getPrivateVendorDetails: builder.query({
      query: ({ id }) => {
        return {
          url: `horeca1_backend/auth/private-vendor/${id}/details`,
        };
      },
    }),
    getMe: builder.query({
      query: () => {
        return {
          url: `/horeca1_backend/auth/me`,
        };
      },
      // async onQueryStarted(id, {queryFulfilled}) {
      //   try {
      //     const result = await queryFulfilled;
      //   } catch (err) {
      //     // api.dispatch(openSessionExpiredDialog(true))
      //     localStorage.clear();
      //     window.location.replace("/");
      //     // throw err;
      //   }
      // },
      providesTags: ["organization-updated", "add-new-org", "user-profile-updated"],
    }),
    savePrefernces: builder.mutation({
      query: ({ payload }) => {
        return {
          url: `/horeca1_backend/organization/preferences/save-preferances`,
          method: "PATCH",
          body: payload,
        };
      },
    }),
    getSuitableVendorList: builder.mutation({
      query: ({ orgId, payload, searchText = "" }) => {
        return {
          url: `/horeca1_backend/search/organization/${orgId}/suitable-vendors?search_text=${searchText}`,
          method: "POST",
          body: payload,
        };
      },
      providesTags: ["organization-updated"],
    }),
    getCategories: builder.query({
      query: () => {
        return {
          url: `/horeca1_backend/categories`,
        };
      },
      providesTags: ['latest-categories']
    }),
    getVendorItems: builder.query({
      query: ({ customerOrgId, vendorOrgId, categoryId, subCategoryId, searchText = "" }) => {
        return {
          url: `/horeca1_backend/search/organization/${customerOrgId}/vendor/${vendorOrgId}?categoryId=${categoryId}&subCategoryId=${subCategoryId}&search_text=${searchText}`,
        };
      },
    }),
    getOrganizationById: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/organization/${orgId}`,
        };
      },
      providesTags: ["deleteZipCode", "org-updated"],
    }),
    saveOrganizationData: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/horeca1_backend/organization`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["organization-updated", "new-org-addded"],
      providesTags: ["org-updated"],
    }),
    getImage: builder.query({
      query: (key) => {
        return {
          url: `/horeca1_backend/file?key=${key}`,
        };
      },
    }),
    uploadOrgLogo: builder.mutation({
      query: ({ orgId, userId = "", payload }) => {
        return {
          url: `/horeca1_backend/organization/file-upload?orgId=${orgId}&userId=${userId}`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["org-logo-updated"],
    }),
    updateItem: builder.mutation({
      query: ({ itemId, payload, orgId }) => {
        return {
          url: `/horeca1_backend/organization/inventory/${itemId}/org/${orgId}`,
          method: "PATCH",
          body: payload,
        };
      },
    }),
    updatePaymentOptions: builder.mutation({
      query: ({ payload, orgId }) => {
        return {
          url: `/horeca1_backend/${orgId}/payWithDiscco`,
          method: "PATCH",
          body: payload,
        };
      },
    }),

    createOrderList: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/v2/organization/${orgId}/order-list`,
          method: "POST",
          body: payload,
        };
      },
    }),
    getAllVendors: builder.query({
      query: ({ orgId, search, page = 0, rowsPerPage = 10 }) => {
        return {
          url: `/horeca1_backend/vendor/organizations?page=${page}&rowsPerPage=${rowsPerPage}&search=${search}`,
        };
      },
      providesTags: ["users", "changeOrgStatus", "org-updated"],
    }),
    getAllCustomers: builder.query({
      query: ({ search, page = 0, rowsPerPage = 10 }) => {
        return {
          url: `/horeca1_backend/customer/organizations?page=${page}&rowsPerPage=${rowsPerPage}&search=${search}`,
        };
      },
      providesTags: ["org-updated", "new-org-addded"],
    }),
    getInventoryItems: builder.query({
      query: ({ orgId }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/inventory/items`,
        };
      },
    }),
    getOrderList: builder.query({
      query: ({ orgId, pincode }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/order-list-summary`,
          method: 'GET',
          params: {
            ...(pincode ? { pincode: pincode } : {}),
          }
        };
      },
    }),
    getOrderListById: builder.query({
      query: ({ orgId, orderListId }) => {
        return {
          url: `/horeca1_backend/v2/organization/${orgId}/order-list/${orderListId}`,
        };
      },
      providesTags: ["order-status"],
    }),
    createNewOrder: builder.mutation({
      query: (payload) => {
        return {
          url: `/horeca1_backend/order`,
          method: "POST",
          body: payload,
        };
      },
    }),
    addNewOrgType: builder.mutation({
      query: ({ userId, type, payload }) => {
        return {
          url: `/horeca1_backend/auth/user/${userId}/organization/${type}`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["add-new-org"],
    }),
    getOrderHistory: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/orderHistory/${orgId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    getOrderDetailsById: builder.query({
      query: ({ orderId, scheduled }) => {
        return {
          url: scheduled ? `/horeca1_backend/scheduleOrder/${orderId}` : `/horeca1_backend/order/${orderId}`,
        };
      },
      providesTags: ["order-updated", "order-status", "payment-status", "scheduled-order-changed"],
    }),
    getMyToDo: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/todo`,
        };
      },
      providesTags: ["to-do-done"],
    }),
    getItemsPriceDetail: builder.mutation({
      query: (payload) => {
        return {
          url: `/horeca1_backend/getPriceDetails`,
          method: "POST",
          body: payload,
        };
      },
    }),
    updateOrderByCustomer: builder.mutation({
      query: ({ orderId, payload }) => {
        return {
          url: `/horeca1_backend/order/customer/${orderId}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["order-updated"],
    }),
    updateOrderByVendor: builder.mutation({
      query: ({ orderId, payload }) => {
        return {
          url: `/horeca1_backend/order/vendor/${orderId}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["order-updated", "payment-status"],
    }),
    updateOrderStatus: builder.mutation({
      query: ({ orderId, status, payload }) => {
        return {
          url: `/horeca1_backend/order/${orderId}/${status}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["order-updated", "order-status"],
    }),
    markAsDeliver: builder.mutation({
      query: ({ orderId, otp, payload }) => {
        return {
          url: `/horeca1_backend/order/${orderId}/${otp}/delivery-verification`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["order-updated", "order-status", "vendor-orders"],
    }),
    markAsDeliverSkipOTP: builder.mutation({
      query: ({ orderId, payload }) => {
        return {
          url: `/horeca1_backend/order/${orderId}/skip-verification`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["order-updated", "order-status", "vendor-orders"],
    }),
    resendDeliveryOTP: builder.mutation({
      query: ({ orderId }) => {
        return {
          url: `/horeca1_backend/order/${orderId}/re-send`,
          method: "PATCH",
        };
      },
    }),
    onBoardPrivateVendor: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/v2/organization/${orgId}/private-vendor`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["private-vendor-added"],
    }),
    getMyVendors: builder.query({
      query: (orgId) => {
        return {
          // url: `/horeca1_backend/customerId/${orgId}/my-vendors`
          url: `/horeca1_backend/${orgId}/my-vendors`,
        };
      },
      providesTags: ["private-vendor-added"],
    }),
    getVendors: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/${orgId}/vendors`,
        };
      },
    }),
    getInvoiceList: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/orderInvoices/${orgId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    downloadInvoice: builder.query({
      query: (orderId) => {
        return {
          url: `/horeca1_backend/order/downloadInvoice/${orderId}`,
        };
      },
      providesTags: ["order-status"],
    }),
    generateInvoice: builder.query({
      query: (orderId) => {
        return {
          url: `/horeca1_backend/order/invoice/${orderId}`,
        };
      },
      providesTags: ["order-status"],
    }),
    getPrivateVendorOrderListDetails: builder.query({
      query: ({ vendorId, customerId, searchText }) => {
        return {
          url: `/horeca1_backend/private-vendor/${vendorId}/customer/${customerId}?searchText=${searchText}`,
        };
      },
      providesTags: ["private-vendor-items"],
    }),
    getPrivateVendorOrderDetails: builder.query({
      query: (orderId) => {
        return {
          url: `/horeca1_backend/private-vendor/v3/order/${orderId}`,
        };
      },
    }),
    getPrivateVendorOrderHistory: builder.query({
      query: (customerId) => {
        return {
          url: `/horeca1_backend/private-vendor/v3/order/customer/${customerId}`,
        };
      },
    }),
    privateVendorOrderHistory: builder.mutation({
      query: ({ customerId, payload }) => {
        return {
          url: `/horeca1_backend/private-vendor/v3/order/customer/${customerId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    createOrderForPrivateVendor: builder.mutation({
      query: ({ vendorId, customerId, payload }) => {
        return {
          url: `/horeca1_backend/private-vendor/${vendorId}/customer/${customerId}`,
          method: "PATCH",
          body: payload,
        };
      },
    }),
    getInvoiceDetail: builder.query({
      query: (invoiceId) => {
        return {
          url: `/horeca1_backend/invoiceDetails/${invoiceId}`,
        };
      },
    }),
    getItemDetailsById: builder.query({
      query: ({ orgId, itemId }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/inventory-item/${itemId}`,
        };
      },
      providesTags: ["getItem-detils"],
    }),
    getContactPersons: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/contactPerson/${orgId}`,
        };
      },
    }),
    uploadItemImg: builder.mutation({
      query: ({ itemId, payload, orgId }) => {
        return {
          url: `/horeca1_backend/organization/item/${itemId}?orgId=${orgId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    getAllVendorItems: builder.mutation({
      query: ({ payload, orgId }) => {
        return {
          url: `/horeca1_backend/v2/organization/vendor/${orgId}/inventory/items`,
          method: "POST",
          body: payload,
        };
      },
      providesTags: ["new-org-addded"],
    }),
    getAllVendorOrders: builder.mutation({
      query: ({ vendorId, payload }) => {
        return {
          url: `/horeca1_backend/orderHistory/vendor/${vendorId}`,
          method: "POST",
          body: payload,
        };
      },
      providesTags: ["vendor-orders"],
    }),
    uploadInventoryFile: builder.mutation({
      query: ({ orgId, payload }) => ({
        url: `/horeca1_backend/${orgId}/upload`,
        body: payload,
        method: "POST",
      }),
    }),
    getScheduledOrderDetails: builder.query({
      query: (orderId) => {
        return {
          url: `/horeca1_backend/scheduleOrder/${orderId}`,
        };
      },
      providesTags: ["order-status"],
    }),
    updateScheduledOrder: builder.mutation({
      query: ({ orderId, payload }) => {
        return {
          url: `/horeca1_backend/scheduledOrder/customer/${orderId}`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    getPaymentLink: builder.query({
      query: (orderId) => {
        return {
          url: `/horeca1_backend/order/generatePaymentUrl/${orderId}`,
        };
      },
    }),
    updatePaymentStatus: builder.mutation({
      query: ({ orderId, status, paymentMode }) => {
        return {
          url: `/horeca1_backend/order/paymentStatus/${orderId}/${status}?paymentMode=${paymentMode}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["order-updated", "payment-status"],
    }),
    updatePaymentMode: builder.mutation({
      query: ({ orderId, orgType, paymentMode }) => {
        return {
          url: `/horeca1_backend/orders/${orderId}/payment-mode?orgType=${orgType}&paymentMode=${paymentMode}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["order-updated", "payment-status"],
    }),
    getAllPermissions: builder.query({
      query: (permissionCategory) => {
        return {
          url: `/horeca1_backend/auth/permissions?permissionCategory=${permissionCategory}`,
        };
      },
    }),
    createNewRole: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/auth/organization/${orgId}/role`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["roles"],
    }),
    getRolesByOrgId: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/auth/organization/${orgId}/roles`,
        };
      },
      providesTags: ["roles"],
    }),
    // getMyRoles: builder.
    addNewUserToOrg: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/auth/organization/${orgId}/user`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["useradded"],
    }),
    getOrgUsers: builder.query({
      query: ({ orgId, filter }) => {
        return {
          url: `/horeca1_backend/auth/organization/${orgId}/users?filter=${filter}`,
        };
      },
      providesTags: ["useradded", "selectedAssignUser", "changeUserStatus"],
    }),
    getOrgOutlets: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/outlets`,
        };
      },
      providesTags: ["prmiaryorg", "changeOrgStatus", "org-logo-updated"],
    }),
    handelApproved: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/status`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["org-updated"],
    }),
    changeOrgStatus: builder.mutation({
      query: ({ orgId, status }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/active/${status}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["users", "changeOrgStatus"],
    }),
    changeUserStatus: builder.mutation({
      query: ({ id, status }) => {
        return {
          url: `/horeca1_backend/auth/user/${id}/active/${status}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["changeUserStatus"],
    }),
    getAssingnedUsers: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/auth/organization/${orgId}/assigned-users`,
        };
      },
      providesTags: ["useradded", "selectedAssignUser"],
    }),
    assingUserToOrg: builder.mutation({
      query: ({ body, orgId }) => {
        return {
          url: `/horeca1_backend/auth/${orgId}/assign-role`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["selectedAssignUser"],
    }),
    removedAssignUserRole: builder.mutation({
      query: ({ orgId, userId, userRoleOrgId, roleId }) => {
        return {
          url: `/horeca1_backend/auth/organization/${orgId}/user/${userId}/role/${roleId}/user-role-org/${userRoleOrgId}`,
          method: "DELETE",
        };
      },
    }),
    changeScheduledOrderStatus: builder.mutation({
      query: ({ orderId, active }) => {
        return {
          url: `/horeca1_backend/order/schedule/${orderId}/${active}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["scheduled-order-changed"],
    }),
    updateItemOffer: builder.mutation({
      query: ({ itemId, payload }) => {
        return {
          url: `/horeca1_backend/organization/item/item-quantity-prices/${itemId}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["getItem-detils"]
    }),
    getRecentOrders: builder.query({
      query: ({ orgId, type }) => {
        return {
          url: `/horeca1_backend/recentOrders/${orgId}/${type}`,
        };
      },
    }),
    deleteZipCodeById: builder.mutation({
      query: ({ orgId, id }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/zipcode/${id}`,
          method: "DELETE",
        };
      },
      // invalidatesTags: ['deleteZipCode']
    }),
    toDoDone: builder.mutation({
      query: ({ orgId, todoId }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/todo/${todoId}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["to-do-done"],
    }),
    getMyCustomers: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/${orgId}/my-customers`,
        };
      },
    }),
    updateUserProfile: builder.mutation({
      query: ({ userId, payload }) => {
        return {
          url: `/horeca1_backend/auth/v2/user/${userId}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["user-profile-updated"],
    }),
    getOrgRatings: builder.mutation({
      query: ({ payload, orgId }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/org-ratings`,
          method: "POST",
          body: payload,
        };
      },
      providesTags: ["ratings-added"],
    }),
    createOrgRatings: builder.mutation({
      query: ({ payload, orgId }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/rating`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["ratings-added"],
    }),
    giveRating: builder.mutation({
      query: ({ toId, fromId, payload }) => {
        return {
          url: `/horeca1_backend/organization/${toId}/${fromId}/rating`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["ratings-added"],
    }),
    getAllSaleOrderForParticularVendor: builder.mutation({
      query: ({ vendorId, customerId, payload, type }) => {
        return {
          url: `/horeca1_backend/orderHistory/vendor/${vendorId}/customer/${customerId}/${type}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    updateOrderListItems: builder.mutation({
      query: ({ orderListId, customerId, payload }) => {
        return {
          url: `/horeca1_backend/organization/${customerId}/order-list/${orderListId}`,
          method: "PATCH",
          body: payload,
        };
      },
    }),
    getAllOrgUsers: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/auth/v2/${orgId}/users`,
        };
      },
      providesTags: ["changeUserStatus", "myUsers", "useradded"],
    }),
    getStatistics: builder.query({
      query: ({ vendorId, customerId }) => {
        return {
          url: `/horeca1_backend/statistics/vendor/${vendorId}/customer/${customerId}`,
        };
      },
    }),
    orderThisMonth: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/ordersThisMonth/${orgId}`,
        };
      },
    }),
    newCustomerThisMonth: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/newCustomers/${orgId}`,
        };
      },
    }),
    getTopOrderingCustomers: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/topOrderingCustomers/${orgId}`,
        };
      },
    }),
    averageDailySales: builder.query({
      //monthly
      query: ({ orgId, type = "weekly" }) => {
        return {
          url: `/horeca1_backend/averageDailySales/${orgId}/${type}`,
        };
      },
    }),
    totalDailySales: builder.query({
      query: ({ orgId, type = "weekly" }) => {
        return {
          url: `/horeca1_backend/totalDailySales/${orgId}/${type}`,
        };
      },
    }),
    topSellingItems: builder.query({
      query: ({ orgId, type = "weekly" }) => {
        return {
          url: `/horeca1_backend/topSelling/${orgId}/${type}`,
        };
      },
    }),
    downloadSampleTemplates: builder.query({
      query: ({ orgType, templateType }) => {
        return {
          url: `/horeca1_backend/downloadTemplate/${orgType}/${templateType}`,
        };
      },
    }),
    //zoho org api's/horeca1_backend/organization/{orgId}/inventory/zoho-org
    getZohoDataByOrgId: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/inventory/zoho-org`,
        };
      },
    }),
    createZohoDetail: builder.mutation({
      query: ({ orgId, payload }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/inventory/zoho-org`,
          method: "POST",
          body: payload,
        };
      },
    }),
    updateZohoDetail: builder.mutation({
      query: (payload) => {
        return {
          url: `/horeca1_backend/inventory/zoho-org`,
          method: "PATCH",
          body: payload,
        };
      },
    }),
    deleteZohoDetail: builder.mutation({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/inventory/zoho-org`,
          method: "DELETE",
        };
      },
    }),
    getAllPinCodes: builder.query({
      query: (orgId) => {
        return {
          url: `/horeca1_backend/v2/organization/${orgId}/shiping-pin-codes`,
        };
      },
      providesTags: ["new-address"],
    }),
    addUpadteAddress: builder.mutation({
      query: ({ payload, orgId }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/shipping-addresses`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["new-address"],
    }),
    getAllCategoriesWithSubCategories: builder.query({
      query: () => {
        return {
          url: `/horeca1_backend/categories-with-sub-categories`,
        };
      },
      providesTags: ['latest-sub-categories']
    }),
    getAllCategoriesByPinCode: builder.query({
      query: ({ pinCode }) => {
        return {
          url: `/horeca1_backend/search/${pinCode}/categories`,
        };
      },
    }),
    getAllVendorsByPinCode: builder.query({
      query: ({ pinCode }) => {
        return {
          url: `/horeca1_backend/search/${pinCode}/vendors`,
        };
      },
    }),
    getTrendProductByPinCode: builder.query({
      query: ({ pinCode }) => {
        return {
          url: `/horeca1_backend/search/${pinCode}/trending-products`,
        };
      },
    }),
    getCategoryProductByPinCode: builder.query({
      query: ({ pinCode }) => {
        return {
          url: `/horeca1_backend/search/${pinCode}/trending-categories`,
        };
      },
    }),
    privateVendorInventoryUpload: builder.mutation({
      query: ({ customerId, vendorId, payload }) => ({
        url: `/horeca1_backend/${customerId}/privateVendor/${vendorId}/uploadItems`,
        body: payload,
        method: "POST",
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        setTimeout(() => {
          dispatch(horecaApi.util.invalidateTags(['private-vendor-items']))
        }, 2000)
      },
    }),
    getCartItems: builder.query({
      query: (orgId) => ({
        url: `/horeca1_backend/cartItems/${orgId}`,
      }),
      providesTags: ["item-removed", "new-item-added"],
    }),
    addItemToCart: builder.mutation({
      query: ({ payload }) => ({
        url: `/horeca1_backend/cart`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["new-item-added"],
    }),
    removeAllItemFromCart: builder.mutation({
      query: (orgId) => ({
        url: `/horeca1_backend/emptyCart/${orgId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["item-removed"],
    }),
    removeItemFromCart: builder.mutation({
      query: (id) => ({
        url: `/horeca1_backend/cartItems/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["item-removed"],
    }),
    updateRate: builder.mutation({
      query: ({ orgId, payload, itemId }) => ({
        url: `/horeca1_backend/organization/org/${orgId}/item/${itemId}`,
        method: "PUT",
        body: payload,
      }),
    }),
    getItemPrice: builder.query({
      query: ({ orgId, itemId, itemQuantity }) => ({
        url: `/horeca1_backend/org/${orgId}/item/${itemId}/quantity/${itemQuantity}`,
      }),
    }),
    topSystemSellingVendors: builder.query({
      query: (type) => ({
        url: `/horeca1_backend/topSellingVendors/${type}`,
      }),
    }),
    updateBillPrice: builder.mutation({
      query: ({ orgId, payload }) => ({
        url: `/horeca1_backend/uploadLastBilledPrices/${orgId}`,
        method: "POST",
        body: payload,
      }),
    }),
    deleteAddress: builder.mutation({
      query: ({ orgId, addressId }) => {
        return {
          url: `/horeca1_backend/organization/${orgId}/shipping-addresses/${addressId}`,
          method: "DELETE",
        };
      },
    }),
    createNewCategory: builder.mutation({
      query: (payload) => {
        return {
          url: `/horeca1_backend/createNewCategory`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ['latest-categories', 'latest-sub-categories']
    }),
    uploadCategorySubCategoryImage: builder.mutation({
      query: ({ categoryId, payload, subCategoryId }) => {
        console.log(subCategoryId);
        return {
          // url: `/horeca1_backend/category/${categoryId}/sub-category/${subCategoryId}/image`,
          url: `/horeca1_backend/category-sub-category/image?categoryId=${categoryId}&subCategoryId=${subCategoryId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    addCategoryForItem: builder.mutation({
      query: (payload) => {
        return {
          url: `/horeca1_backend/inventry/item-multi-cat-mapping`,
          method: "POST",
          body: payload,
        };
      },
    }),
    removeCategoryFromItem: builder.mutation({
      query: (mappingId) => {
        return {
          url: `/horeca1_backend/inventry/item/${mappingId}/remove-multi-cat`,
          method: "DELETE",
        };
      },
    }),
    getAllShippingAddressOutletWise: builder.mutation({
      query: ({ payload }) => {
        return {
          url: `/horeca1_backend/organization/outlet-shipping-addresses`,
          method: "POST",
          body: payload,
        };
      },
    }),
    updateCartItemQuantity: builder.mutation({
      query: ({ cartItemId, qty }) => {
        return {
          url: `/horeca1_backend/cartItems/${cartItemId}/quantity/${qty}`,
          method: "PATCH",
        };
      },
    }),
    updateOrderListName: builder.mutation({
      query: ({ orderListId, payload }) => {
        return {
          url: `/horeca1_backend/order-list-name/${orderListId}`,
          method: "PATCH",
          body: payload,
        };
      },
    }),
    removeSubcategory: builder.mutation({
      query: ({ categoryId, subCategoryId }) => {
        return {
          url: `/horeca1_backend/${categoryId}/sub-category/${subCategoryId}`,
          method: "DELETE"
        }
      },
      invalidatesTags: ["latest-sub-categories"],
    }),
    getbannerImages: builder.query({
      query: () => ({
        url: `/horeca1_backend/master/banner/retrieve`,
      }),
    }),
  }),
});

export const {
  useGetOtpMutation,
  useLoginMutation,
  useUploadItemImgMutation,
  useGetItemDetailsByIdQuery,
  useUploadInventoryFileMutation,
  useGetAllVendorItemsMutation,
  useGetAllVendorOrdersMutation,
  useUpdateItemMutation,
  useSignupMutation,
  useGetRolesQuery,
  useSavePreferncesMutation,
  useGetMeQuery,
  useGetSuitableVendorListMutation,
  useSaveOrganizationDataMutation,
  useGetImageQuery,
  useUploadOrgLogoMutation,
  useCreateOrderListMutation,
  useCreateNewOrderMutation,
  useAddNewOrgTypeMutation,
  useGetOrderHistoryMutation,
  useGetItemsPriceDetailMutation,
  useUpdateOrderByCustomerMutation,
  useUpdateOrderByVendorMutation,
  useUpdateOrderStatusMutation,
  useOnBoardPrivateVendorMutation,
  useGetMyVendorsQuery,
  useGetInvoiceListMutation,
  useCreateOrderForPrivateVendorMutation,
  useGetInvoiceDetailQuery,
  useUpdateScheduledOrderMutation,
  usePrivateVendorOrderHistoryMutation,
  useGetOrderDetailsByIdQuery,
  useGetScheduledOrderDetailsQuery,
  useGetCategoryProductByPinCodeQuery,
  useGetVendorsQuery,
  useGetAllPermissionsQuery,
  useCreateNewRoleMutation,
  useAddNewUserToOrgMutation,
  useGetRolesByOrgIdQuery,
  useGetOrgUsersQuery,
  useGetOrgOutletsQuery,
  useGetOrganizationByIdQuery,
  useHandelApprovedMutation,
  useChangeOrgStatusMutation,
  useChangeUserStatusMutation,
  useGetAllCategoriesByPinCodeQuery,
  useGetAssingnedUsersQuery,
  useAssingUserToOrgMutation,
  useRemovedAssignUserRoleMutation,
  useChangeScheduledOrderStatusMutation,
  useUpdateItemOfferMutation,
  useGetRecentOrdersQuery,
  useDeleteZipCodeByIdMutation,
  useToDoDoneMutation,
  useGetMyCustomersQuery,
  useUpdateUserProfileMutation,
  useGetOrgRatingsMutation,
  useCreateOrgRatingsMutation,
  useGetAllSaleOrderForParticularVendorMutation,
  useUpdateOrderListItemsMutation,
  useGetAllOrgUsersQuery,
  useGetStatisticsQuery,
  useOrderThisMonthQuery,
  useUpdatePaymentOptionsMutation,
  useUpdatePaymentStatusMutation,
  useNewCustomerThisMonthQuery,
  useGetTopOrderingCustomersQuery,
  useMarkAsDeliverSkipOTPMutation,
  useAverageDailySalesQuery,
  useTotalDailySalesQuery,
  useTopSellingItemsQuery,
  useGiveRatingMutation,
  useGetZohoDataByOrgIdQuery,
  useDeleteZohoDetailMutation,
  useUpdateZohoDetailMutation,
  useCreateZohoDetailMutation,
  useGetAllVendorsByPinCodeQuery,
  useGetTrendProductByPinCodeQuery,
  useAddUpadteAddressMutation,
  useGetAllCategoriesWithSubCategoriesQuery,
  usePrivateVendorInventoryUploadMutation,
  useAddItemToCartMutation,
  useGetCartItemsQuery,
  useRemoveAllItemFromCartMutation,
  useUpdatePaymentModeMutation,
  useRemoveItemFromCartMutation,
  useResendDeliveryOTPMutation,
  useGetAllPinCodesQuery,
  useUpdateRateMutation,
  useTopSystemSellingVendorsQuery,
  useUpdateBillPriceMutation,
  useMarkAsDeliverMutation,
  useGetCategoriesQuery,
  useCreateNewCategoryMutation,
  useAddCategoryForItemMutation,
  useRemoveCategoryFromItemMutation,
  useDeleteAddressMutation,
  useUploadCategorySubCategoryImageMutation,
  useGetAllShippingAddressOutletWiseMutation,
  useUpdateCartItemQuantityMutation,
  useUpdateOrderListNameMutation,
  useRemoveSubcategoryMutation,
  useGetbannerImagesQuery
} = horecaApi;
