import React, { useEffect, useState, useCallback } from "react";
import { base64ToArrayBuffer } from "../../utils/base64toArray";
import { noImage } from "../../assets/images";
import { horecaApi } from "../../redux/api/api";

const GetImageComponent = ({ s3Key, style, noSticker, nocardSticker }) => {
  console.log("nocardSticker", s3Key)
  // console.log("nocardSticker noImage", noImage)
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [getImage, { isLoading }] = horecaApi.endpoints.getImage.useLazyQuery();

  const handleImageError = () => {
    setImage(noImage);
  };

  const fetchImage = useCallback(async (key) => {
    try {
      setLoading(true);
      const encodedKey = encodeURIComponent(key);
      const result = await getImage(encodedKey);
      if (result?.data) {
        result?.data?.publicUri ? setImage(result?.data?.publicUri) : setImage(noImage)
      } else {
        result?.publicUri ? setImage(result?.publicUri) : setImage(noImage)
      }
      return
      if (result.data && result.data.img) {
        const byteData = base64ToArrayBuffer(result.data.img);
        const urlCreator = window.URL.createObjectURL(
          new Blob([byteData], { type: "application/octet-stream" })
        );
        setImage(urlCreator);
      } else {
        setImage(noImage);
      }
    } catch (error) {
      console.error("Failed to fetch image:", error);
      setImage(noImage);
    } finally {
      setLoading(false);
    }
  }, [getImage]);

  useEffect(() => {
    const key = s3Key ? s3Key : noSticker;
    if (key) {
      fetchImage(key);
    } else {
      setImage(noImage);
      setLoading(false);
    }
  }, [s3Key, noSticker, fetchImage]);

  return nocardSticker ? (
    <img
      src={s3Key ? image : nocardSticker}
      onError={handleImageError}
      alt="image"
      style={{ ...style, objectFit: "contain" }}
      loading="auto"
    />
  ) : (
    <img
      src={loading ? noImage : image}
      onError={handleImageError}
      alt="image"
      style={{ ...style, objectFit: "contain" }}
      loading="auto"
    />
  );
};

export default GetImageComponent;
