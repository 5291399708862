import { useSelector } from "react-redux";
import { NewCustomerThisMonth, OrderThisMonth } from "./AvgNewOrder";
import { Grid } from "@mui/material";
import { useGetTopOrderingCustomersQuery, useNewCustomerThisMonthQuery, useOrderThisMonthQuery } from "../../../../../redux/api/api";

const CustomerOrderData = () => {
    const orgId = useSelector((state) => state.app.preference.selectedOrgId);
    const { data: orderThisMonthData } = useOrderThisMonthQuery(orgId, { enabled: !!orgId });
    const { data: customerThisMonthData } = useNewCustomerThisMonthQuery(orgId, { enabled: !!orgId });
    const { data: topOrderCustomersData } = useGetTopOrderingCustomersQuery(orgId, { enabled: !!orgId });
    return (
        <Grid container item xs={12} md={12} spacing={3}>
            <Grid item md={6}>
                {orderThisMonthData && <OrderThisMonth data={orderThisMonthData} />}
            </Grid>
            <Grid item md={6}>
                {customerThisMonthData && topOrderCustomersData && (
                    <NewCustomerThisMonth
                        data={customerThisMonthData}
                        topOrderCustomerData={topOrderCustomersData}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default CustomerOrderData;