import { Box, LinearProgress, Paper, Stack, Typography, styled } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import React, { useEffect, useMemo, useState } from 'react'
import Chart, { useChart } from '../../../../../components/chart';
import { fNumber } from '../../../../../utils/formatNumbers';
import GetImageComponent from '../../../../../components/common/GetImageComponent';
import { useAverageDailySalesQuery } from '../../../../../redux/api/api';
import { useSelector } from 'react-redux';

const ProgressContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const useAverageDailySalesSeries = (averageDailySalesData) => {
  return useMemo(() => {
    if (!averageDailySalesData) return [];
    return Object.entries(averageDailySalesData).map(([date, data]) => ({
      label: date,
      value: data?.averagePrice ?? 0, // Nullish coalescing for clarity
    }));
  }, [averageDailySalesData]);
};

export const AvergaeDailySales = () => {
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const { data: averageDailySalesData } = useAverageDailySalesQuery({ orgId }, { enabled: !!orgId });
  const averageDailySalesSeries = useAverageDailySalesSeries(averageDailySalesData);

  const chartSeries = useMemo(
    () => averageDailySalesSeries.map((i) => i?.value || 0),
    [averageDailySalesSeries]
  );

  console.log('avg day in QA:', averageDailySalesSeries);
  //const chartSeries = series?.map((i) => i.value);


  const chartOptions = useChart({
    // colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (value) => value,
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      //categories: series?.map((i) => ''),
      categories: averageDailySalesSeries?.length > 0 ? averageDailySalesSeries.map((item) => item.label) : ['No Data'],
    },
    yaxis: {
      labels: {
        formatter: (value) => fNumber(value), // Ensure valid y-axis labels
      },
    },
    // ...options,
  });
  const value = averageDailySalesSeries[averageDailySalesSeries?.length - 1]?.value;
  const formattedValue = value ? Number(value).toFixed(2) : 0;

  return (
    <Paper sx={{ borderRadius: '16px', padding: '1rem', height: '100%' }}>
      <Stack justifyContent={'space-between'} height={'100%'}>
        <Stack justifyContent={'flex-start'}>
          <Typography variant='h2' fontWeight={550}>
            <span style={{ fontSize: '20px', color: 'gray', alignSelf: 'flex-start', }}>₹ </span>{formattedValue}
          </Typography>
          <Typography variant='body1' color={'text.disabled'}> Average Daily Sales</Typography>
        </Stack>
        <Stack>
          {/* need 7 bars with tooltip */}
          <Chart 
          key={orgId}
            dir="ltr"
            type="bar"
            series={[{ data: chartSeries }]}
            options={chartOptions}
          // width="100%"
          // height={250}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}


export const OrderThisMonth = ({ data }) => {

  return (
    <Paper sx={{ borderRadius: '16px', padding: '1rem', height: '100%' }}>
      <Stack justifyContent={'space-between'} height={'100%'}>
        <Stack justifyContent={'flex-start'}>
          <Typography variant='h2' fontWeight={550}>
            {data?.currentMonthCount}
          </Typography>
          <Typography variant='body1' color={'text.disabled'}>Orders This Month</Typography>
        </Stack>
        <Stack>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(data?.remainingOrders)}-Remaining orders`}</Typography>
          </Box>
          <ProgressContainer>
            <Box width="100%" mr={1}>
              <LinearProgress variant="buffer" value={data?.achievedPercent} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(data?.achievedPercent)}%`}</Typography>
            </Box>
          </ProgressContainer>
        </Stack>
      </Stack>
    </Paper>
  )
}



export const NewCustomerThisMonth = ({ data, topOrderCustomerData }) => {


  const handleClick = () => {

  }

  const topThreeOrLessCustomers = topOrderCustomerData?.length > 3
    ? topOrderCustomerData?.slice(0, 3)
    : topOrderCustomerData;

  return (
    <Paper sx={{ borderRadius: '16px', padding: '1rem', height: '100%' }}>
      <Stack justifyContent={'space-between'} height={'100%'}>

        <Stack justifyContent={'flex-start'}>
          <Typography variant='h2' fontWeight={550}>
            {data?.currentMonthCount}
          </Typography>
          <Typography variant='body1' color={'text.disabled'}>New Customers This Month</Typography>
        </Stack>
        <Stack>
          <Typography variant='subtitle1'>Today’s Heroes</Typography>
          <AvatarGroup
            renderSurplus={(surplus) => <span>+</span>}
            // renderSurplus={(surplus) => <span>+{surplus.toString()[0]}k</span>}
            total={topOrderCustomerData?.length}
            onClick={handleClick}
          >
            {
              topThreeOrLessCustomers?.map((customer, index) => (
                <Avatar alt={customer?.name} >
                  <GetImageComponent s3Key={customer?.logoKey} key={index} />
                </Avatar>
              ))
            }
          </AvatarGroup>
        </Stack>
      </Stack>

    </Paper>
  )
}