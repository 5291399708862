import { Box, Button, IconButton, ListItem, MenuItem, Popover, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from './Iconify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ViewBulkQuantityPrices = ({ row, style, handleAddToCart, quantity1, setQuantity1, selectedBulkIndex }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonText, setButtonText] = useState();

  useEffect(() => {
    if(row?.itemQuantityPricesMappingDetails[selectedBulkIndex]?.itemPrice) {
        setButtonText(`₹${row?.itemQuantityPricesMappingDetails[selectedBulkIndex]?.itemPrice} - ${row?.itemQuantityPricesMappingDetails[selectedBulkIndex]?.itemQuantity}/${row.unit}`)
    }
  }, [row, selectedBulkIndex])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleIncrease = () => {
  //   setQuantity1(quantity1 + 1);
  //   handleAddToCart(data?.itemId, 1, data?.vendorId)
  // };

  const addItem = (quantity, index, bulkId) => {
    handleAddToCart(row?.itemId, parseInt(quantity?.itemQuantity), quantity.id);
    setAnchorEl(null);
  }

  return (
    <Box>
      {row?.itemQuantityPricesMappingDetails?.length > 0 && (
        <Button fullWidth onClick={handleClick} 
          sx={{ fontSize: '12px', padding: 0.5, border: '0.5px solid #2066D1', px:1, borderRadius: 0.2, justifyContent: 'space-between' }} 
          variant='text' 
          endIcon={<ArrowDropDownIcon />}
        // endIcon={<IconButton size="small" sx={{
        //   padding: 0, minHeight: '20px', color: 'white', ':hover': {
        //     background: 'none'
        //   }
        // }}>
        //   <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
        // </IconButton>}
        >
          {buttonText}
        </Button>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : undefined,
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        {row?.itemQuantityPricesMappingDetails?.map((rate, index) => (
          <MenuItem key={index} sx={{ minHeight: '24px' }} onClick={() => addItem(rate, index)}>
            <Typography variant="body2">
              {/* {"₹" + rate.itemPrice} [{rate.itemQuantity + row.unit}] */}
              ₹{rate?.itemPrice}
              <span style={{ color: "#2065D1", fontWeight : 'bold' }}> per unit for </span>
              - {rate?.itemQuantity}/{row.unit}
            </Typography>
            {/* <IconButton> */}
            {/* <Iconify icon="ic:round-add" /> */}
            {/* </IconButton> */}
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};

export default ViewBulkQuantityPrices;
