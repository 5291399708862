import { Avatar, Tooltip } from "@mui/material";
import { horecaApi } from "../../../redux/api/api";
import { base64ToArrayBuffer } from "../../../utils/base64toArray";
import { useEffect, useState } from "react";
import noImage from "../../../assets/images/noImage.png";

export default function CustomLogo({ src, size, title }) {
    const [image, setImage] = useState(null);

    const [getImage] = horecaApi.endpoints.getImage.useLazyQuery();

    useEffect(() => {
        (async () => {
            if (src) {
                const encodedKey = encodeURIComponent(src)
                const result = await getImage(encodedKey);
                return result?.data?.publicUri ?? null
                if (result.data?.img) {
                    const byteArray = base64ToArrayBuffer(result?.data?.img);
                    const rr = new Blob([byteArray], { type: "application/octet-stream" });
                    const reader = new FileReader();
                    reader.readAsDataURL(rr);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setImage(base64data);
                    };
                }
            }
        })();
    }, [src])

    return (
        <>
            <Tooltip title={title ?? ""}>
                <Avatar
                    alt='usre1'
                    // variant='rounded'
                    src={image ?? noImage}
                    sx={{
                        width: `${size / 16}rem` ?? '3.5rem',
                        height: `${size / 16}rem` ?? '3.5rem',
                        cursor: "pointer",
                        bgcolor: (theme) => theme.palette.primary.lighter,
                        color: (theme) => theme.palette.primary.main,
                        borderRadius: "12px"
                    }}
                />
            </Tooltip>
        </>
    )
}
