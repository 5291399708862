import React from "react";
import { Box, Container, Grid, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material";
import Iconify from "../../../components/common/Iconify";
import {
  horecaApi,
  useCreateNewOrderMutation,
  useGetAllPinCodesQuery,
  useGetItemsPriceDetailMutation,
  useUpdateOrderByCustomerMutation,
  useUpdatePaymentStatusMutation,
  useUpdateScheduledOrderMutation,
} from "../../../redux/api/api";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import OrderListTable from "./OrderListTable";
import PlaceOrderDialogue from "./PlaceOrderDialogue";
import { useDispatch, useSelector } from "react-redux";
import { addAdnUpateItemToOrder } from "../../../redux/slices/orderSlice";
import { useState } from "react";
import { openMessage } from "../../../redux/slices/showMessageSlice";
import { useRouter } from "../../../routes/hooks/useRouter";
import { DailySchedule, ParticularDateSchedule, WeeklySchedule } from "./SheduleComponent";
import { formatDateAndTime } from "../../../utils/formatNumbers";
import VendorLogoImageComponent from "../../../components/common/VendorLogoImageComponent";
import PaymentPage from "../order-details/PaymentPage";
import PageHeader from "../../../components/common/PageHeader";
import CustomButton from "../../../components/common/CustomButton";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";

//get items with quantity
export const getItemsWithQuantity = (items, selectedItems) => {
  if (selectedItems.length === 0) {
    return [];
  }

  const filteredItems = items
    ?.filter((item) => selectedItems.some((selectedItem) => selectedItem.itemId === item.id))
    .map((item) => ({
      ...item,
      quantity: selectedItems.find((selectedItem) => selectedItem.itemId === item.id).quantity,
    }));
  return filteredItems;
};

const SheduleButton = ({ handleSchedule, selectedOption, anchorEl, handleOptionClick, handleClose, handleClick }) => {
  return (
    <>
      <CustomButton
        onClick={handleClick}
        startIcon={<Iconify icon="material-symbols:schedule" />}
        variant={"contained"}
        label={selectedOption ? selectedOption : "Schedule"}
        width={"250px"}
      />
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleOptionClick("day")}>For a particular date</MenuItem>
        <MenuItem onClick={() => handleOptionClick("daily")}>Daily(Time)</MenuItem>
        <MenuItem onClick={() => handleOptionClick("weekly")}>Weekly(Day and Time)</MenuItem>
      </Menu>
    </>
  );
};

const PlaceOrder = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const newOrder = useSelector((state) => state.order.newOrder);
  const [getItemsPriceDetail, { data: priceDetails }] = useGetItemsPriceDetailMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");
  const currStatus = searchParams.get("status");

  const [isReview, setIsReview] = useState(false);

  const { orgId, orderListId } = useParams();
  const [getOrderListById, { data: orderListData }] = horecaApi.endpoints.getOrderListById.useLazyQuery();
  const [getOrderDetailsById] = horecaApi.endpoints.getOrderDetailsById.useLazyQuery();
  const [updateOrderByCustomer] = useUpdateOrderByCustomerMutation();
  const [updateScheduledOrder] = useUpdateScheduledOrderMutation();
  const [createNewOrder] = useCreateNewOrderMutation();
  const [getScheduledOrderDetails] = horecaApi.endpoints.getScheduledOrderDetails.useLazyQuery();
  //const [updatePaymentStatus] = horecaApi.endpoints.updatePaymentStatus.useLazyQuery();
  const [updatePaymentStatus] = useUpdatePaymentStatusMutation();
  const { data: add } = useGetAllPinCodesQuery(orgId);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [selectedType, setSelectedType] = useState(null);
  const [selectdDetails, setSelectedDetails] = useState([]);
  const selectedValue = useSelector((state) => state.app.preference.selectedPinCode);

  const [paymentData, setPaymentData] = useState();

  const [openPayment, setOpenPayment] = useState(false);
  const [thankYou, setThanYou] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentClose = () => {
    router.push(`/org/${orgId}/order-history/${selectedOrderId}`);
    setOpenPayment(false);
  };

  //call an api and show loader once api is done recall the query an update state and show thank you
  const afterPayClick = async (paymentMode = 'payByGateway') => {
    setIsLoading(true);
    const result = await updatePaymentStatus({ orderId: selectedOrderId, status: 'success', paymentMode });
    if (result.data || result.isSuccess) {
      setTimeout(() => {
        setIsLoading(false);
        setThanYou(true);
      }, 2000);
      setTimeout(() => {
        router.push(`/org/${orgId}/order-history/${selectedOrderId}`);
      }, 3000);
    }
  };

  const handleDayToggle = (day) => {
    if (selectdDetails.includes(day)) {
      setSelectedDetails(selectdDetails.filter((d) => d !== day));
    } else {
      setSelectedDetails([...selectdDetails, day]);
    }
  };

  const handleDateChange = (date) => {
    const a = formatDateAndTime(date);
    setSelectedDetails([a.formattedDate]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setSelectedDetails([]);
    setSelectedType(option);
    handleClose();
  };

  useEffect(() => {
    (async () => {
      await getOrderListById({ orgId, orderListId });

      if (orderId) {
        if (currStatus !== "scheduled") {
          const result = await getOrderDetailsById({ orderId });
          if (result?.data) {
            const transformedData = result?.data?.items.map((item) => ({
              itemId: item.itemId,
              quantity: item.quantity,
              unit: item.unit,
            }));
            dispatch(addAdnUpateItemToOrder(transformedData));
          }
        } else {
          const result = await getScheduledOrderDetails(orderId);
          if (result?.data) {
            const transformedData = result?.data?.items.map((item) => ({
              itemId: item.itemId,
              quantity: item.quantity,
              unit: item.unit,
            }));
            dispatch(addAdnUpateItemToOrder(transformedData));
          }
        }
      }
    })();
  }, []);

  const handleSchedule = async () => {};

  //get items here on onChange and make cahnge in reducer once items are there then make it t
  const handleQuantityChange = (itemId, itemName, itemUnit, newQuantity) => {
    let updatedItems = [];

    if (newQuantity > 0) {
      // Quantity is greater than zero, update or add the item to the newOrder state
      updatedItems = newOrder.items.map((item) => {
        if (item.itemId === itemId) {
          // Update quantity of existing item
          return { ...item, quantity: newQuantity };
        } else {
          return item;
        }
      });

      // Check if the item already existed
      const existingItemIndex = updatedItems.findIndex((item) => item.itemId === itemId);

      if (existingItemIndex === -1) {
        // Item does not exist, add it to the items array
        updatedItems.push({
          itemId: itemId,
          quantity: newQuantity,
          unit: itemUnit,
        });
      }
      dispatch(addAdnUpateItemToOrder(updatedItems ? updatedItems : []));
    } else {
      // Quantity is zero or negative, remove the item from the newOrder state if it exists
      updatedItems = newOrder.items.filter((item) => item.itemId !== itemId);
    }
    dispatch(addAdnUpateItemToOrder(updatedItems ? updatedItems : []));
  };

  const handleQuantityChange1 = (itemId, itemName, itemUnit, newQuantity) => {
    let updatedItems = [];

    if (true) {
      // Quantity is greater than zero, update or add the item to the newOrder state
      updatedItems = newOrder.items.map((item) => {
        if (item.itemId === itemId) {
          // Update quantity of existing item
          return { ...item, quantity: newQuantity };
        } else {
          return item;
        }
      });

      // Check if the item already existed
      const existingItemIndex = updatedItems.findIndex((item) => item.itemId === itemId);

      if (existingItemIndex === -1) {
        // Item does not exist, add it to the items array
        updatedItems.push({
          itemId: itemId,
          quantity: newQuantity,
          unit: itemUnit,
        });
      }
      dispatch(addAdnUpateItemToOrder(updatedItems ? updatedItems : []));
    }
    dispatch(addAdnUpateItemToOrder(updatedItems ? updatedItems : []));
  };

  useEffect(() => {
    dispatch(addAdnUpateItemToOrder([]));
  }, []);

  const updateQuantityFromSelectedItems1 = (items, selectedItems) => {
    if (selectedItems?.length > 0) {
      const updateItems = items?.map((item) => {
        const selectedItem = selectedItems.find((selectedItem) => selectedItem.itemId === item.id);
        if (selectedItem) {
          const quantity = selectedItem.quantity || 0;
          return { ...item, quantity };
        } else {
          const quantity = 0;
          return { ...item, quantity };
        }
      });
      return updateItems;
    } else {
      const updatedItems = items?.map((item) => ({ ...item, quantity: 0 }));
      return updatedItems;
    }
  };

  const goBack = () => {
    router.back(-1);
  };

  const [addressId, setAddressId] = useState(selectedValue?.id);

  const handleReviewClose = () => {
    setIsReview(false);
    dispatch(addAdnUpateItemToOrder(newOrder?.items.filter((item) => item.quantity > 0)));
  };

  const onDelete = (itemId) => {
    const filteredItems = newOrder?.items.filter((item) => item?.itemId !== itemId);
    dispatch(addAdnUpateItemToOrder(filteredItems));
  };

  const placeOrder = async () => {
    const payload = {
      orderListId: orderListId,
      items: newOrder?.items.filter((item) => item.quantity > 0),
    };

    if (selectedType) {
      payload.schedule = {
        type: selectedType,
        details: selectdDetails,
      };
    }

    if (orderId && currStatus !== "scheduled") {
      const result = await updateOrderByCustomer({ orderId, payload });
      if (result?.data) {
        dispatch(openMessage({ message: result?.data?.message ? result?.data?.message : "Order updated!", messageSeverity: "success" }));
        goBack();
        dispatch(addAdnUpateItemToOrder([]));
      } else if (result?.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
      } else {
        dispatch(openMessage({ message: "Not able to update an order at this moment!", messageSeverity: "error" }));
      }
    } else if (orderId && currStatus === "scheduled") {
      const result = await updateScheduledOrder({ orderId, payload });
      if (result?.data) {
        dispatch(openMessage({ message: result?.data?.message ? result?.data?.message : "Order updated!", messageSeverity: "success" }));
        goBack();
        dispatch(addAdnUpateItemToOrder([]));
      } else if (result?.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
      } else {
        dispatch(openMessage({ message: "Not able to update an order at this moment!", messageSeverity: "error" }));
      }
    } else {
      const filteredAddress = add?.addresses?.find((address) =>
      selectedValue?.pincode === address?.pincode
    );
      payload.type = "normal";
      payload.addressId = filteredAddress?.id;
      const result = await createNewOrder([payload]);
      if (result?.data) {
        dispatch(openMessage({ message: result?.data?.message ? result?.data?.message : "New Order Created!", messageSeverity: "success" }));
        setPaymentData(result?.data);
        if (selectedType) {
          goBack();
          dispatch(addAdnUpateItemToOrder([]));
        } else {
          setSelectedOrderId(result?.data?.orderId);
          setOpenPayment(true);
          dispatch(addAdnUpateItemToOrder([]));
        }
      } else if (result?.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
      } else {
        dispatch(openMessage({ message: "Not able to create an order at this moment!", messageSeverity: "error" }));
      }
    }
  };

  const handleIncrement = (itemId, itemName, itemUnit, newQuantity) => {
    if (!isNaN(newQuantity)) {
      handleQuantityChange(itemId, itemName, itemUnit, newQuantity);
    }
  };

  const handleDecrement = (itemId, itemName, itemUnit, newQuantity) => {
    if (!isNaN(newQuantity)) {
      handleQuantityChange(itemId, itemName, itemUnit, newQuantity);
    }
  };

  const handelRemoveItem = async (itemId) => {
    // dispatch(removeAnItemFromSalesOrder({ lineItemId: itemId }));
  };

  const handlePlaceAndReview = async () => {
    const payload = {
      orderListId: orderListId,
      items: newOrder?.items,
    };
    await getItemsPriceDetail([payload]);
    setIsReview(true);
  };

  const renderScheduleComponent = () => {
    switch (selectedType) {
      case "weekly":
        return <WeeklySchedule selectedDays={selectdDetails} handleDayToggle={handleDayToggle} />;
      case "daily":
        return <DailySchedule />;
      case "day":
        return <ParticularDateSchedule selectedDate={selectdDetails} handleDateChange={handleDateChange} />;
      default:
        return null;
    }
  };

  const { isMobile } = useResponsiveStyles();

  return (
    <Container maxWidth="xl">
      <PageHeader handleBackClick={goBack} title={orderListData?.name} />
      <Paper sx={{ padding: isMobile ? "20px 20px 10px 20px" : "40px 40px 20px 40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <VendorLogoImageComponent s3Key={orderListData?.vendorDetails?.orgLogoKey} name={orderListData?.vendorDetails?.orgName} />
            <Typography variant="h6" sx={{ mt: "1rem" }} gutterBottom>
              Vendor Details
            </Typography>
            <Grid container>
              {[{ label: "Name", value: orderListData?.vendorDetails?.orgName }].map((vendorDet, index) => (
                <>
                  <Grid item xs={6} container key={index}>
                    <Typography sx={{ color: "text.disabled" }} variant="subtitle2" gutterBottom>
                      {vendorDet.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} padding={"auto"} key={index}>
                    <Typography variant="body2" gutterBottom>
                      {vendorDet.value}
                    </Typography>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={6} container>
            <Stack width={"100%"} justifyContent={"flex-start"} alignItems={"flex-end"}>
              <SheduleButton
                handleSchedule={handleSchedule}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleOptionClick={handleOptionClick}
                handleClick={handleClick}
                selectedOption={selectedType}
              />
              <Stack mt={"2rem"}>{selectedType && renderScheduleComponent()}</Stack>
            </Stack>
          </Grid> */}
        </Grid>
        <Box sx={{ mt: "1rem" }}>
          <OrderListTable
            items={
              orderId
                ? updateQuantityFromSelectedItems1(orderListData?.items, newOrder?.items)
                : updateQuantityFromSelectedItems1(orderListData?.items, newOrder?.items)
            }
            handleQuantityChange={handleQuantityChange}
            action={false}
            title={"Items"}
            prevOrders={orderListData?.previousOrderDetail?.items}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            handleRemove={handelRemoveItem}
          />
          <Stack direction={"row"} justifyContent={"center"} mt={"2rem"}>
            <CustomButton
              onClick={handlePlaceAndReview}
              variant={"contained"}
              label={"Place and Preview"}
              disabled={newOrder?.items?.length > 0 ? false : true}
            />
          </Stack>
        </Box>
      </Paper>
      <PlaceOrderDialogue
        heading={"Preview"}
        priceDetails={priceDetails}
        addressId={addressId}
        setAddressId={setAddressId}
        handelCreateOrder={placeOrder}
        open={isReview}
        itemLength={newOrder?.items?.length > 0 ? newOrder?.items.filter((item) => item.quantity > 0) : []}
        content={
          <OrderListTable
            items={getItemsWithQuantity(orderListData?.items, newOrder?.items)}
            handleQuantityChange={handleQuantityChange1}
            action={isReview}
            onDelete={onDelete}
            title={"Selected Items"}
            prevOrders={orderListData?.previousOrderDetail?.items}
            disabled={isReview}
          />
        }
        onClose={handleReviewClose}
      />

      <PaymentPage
        open={openPayment}
        handleClose={handlePaymentClose}
        isThankYou={thankYou}
        afterPayClick={afterPayClick}
        isLoading={isLoading}
        price={priceDetails?.total}
        paymentData={paymentData}
      />
    </Container>
  );
};

export default PlaceOrder;
