import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Box, IconButton, styled } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { horecaApi, useGetMeQuery, useUploadOrgLogoMutation } from "../../redux/api/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { base64ToArrayBuffer } from "../../utils/base64toArray";
import { stringAvatar } from "../../utils/defaultIcon";

const Input = styled("input")({
  display: "none",
});

const ProfilePic = ({ s3key, userId, orgId, disabled, name = "user" }) => {
  // const {orgId} = useParams();
  // const userId = useSelector((state)=> state.auth.user.id);
  const [getMe] = horecaApi.endpoints.getMe.useLazyQuery();
  const [profilePic, setProfilePic] = useState(null);
  const [uploadOrgLogo] = useUploadOrgLogoMutation();
  const [getImage] = horecaApi.endpoints.getImage.useLazyQuery();

  let nameColor = stringAvatar({ name, color: "rgb" })?.user;

  useEffect(() => {
    (async () => {
      if (s3key) {
        const encodedKey = encodeURIComponent(s3key);
        const result = await getImage(encodedKey);
        return result?.data?.publicUri ?? null
        if (result.data?.img) {
          const byteArray = base64ToArrayBuffer(result?.data?.img);
          const rr = new Blob([byteArray], { type: "application/octet-stream" });
          const reader = new FileReader();
          reader.readAsDataURL(rr);
          reader.onloadend = function () {
            const base64data = reader.result;
            setProfilePic(base64data);
          };
        }
      }
    })();
  }, [s3key])

  const handlePicChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setProfilePic(e.target.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      const result = await uploadOrgLogo({
        orgId,
        userId: userId,
        payload: formData,
      });
      if (result?.data) {
        await getMe();
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <label htmlFor="icon-button-file">
          <Input
            accept="image/*"
            id="icon-button-file"
            type="file"
            onChange={handlePicChange}
            disabled={disabled}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{ borderRadius: "10%", border: (theme) => `1px dashed ${theme.palette.grey[300]}` }}
          >
            {profilePic ?
              <Avatar
                alt="Profile Picture"
                src={profilePic ? profilePic : ""}
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: "10%",
                  "&:hover": {
                    borderRadius: "10%", // Keeps the border radius of 10% on hover
                  },
                }}
              >
                <PhotoCamera fontSize="large" />
              </Avatar> :
              <Avatar
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: "10%",
                  fontSize: "60px",
                  "&:hover": {
                    borderRadius: "10%", // Keeps the border radius of 10% on hover
                  },
                  bgcolor: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},0.5)` : `rgba(${nameColor?.sx?.bgcolor},0.7)`,
                  color: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},1)` : `rgba(255,255,255,0.9)`
                }}
                children={nameColor?.children}
              />
            }
          </IconButton>
        </label>
      </Box>
    </Box >
  );
};

export default ProfilePic;
