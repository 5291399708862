import { Container, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { AvergaeDailySales, NewCustomerThisMonth, OrderThisMonth } from './VendorDashboardComponents/AvgNewOrder';
import SalesThisMonth from './VendorDashboardComponents/SalesThisMonth';
import { useSelector } from 'react-redux';
import {
  useAverageDailySalesQuery,
  useGetTopOrderingCustomersQuery,
  useNewCustomerThisMonthQuery,
  useOrderThisMonthQuery,
  useTopSellingItemsQuery,
  useTotalDailySalesQuery
} from '../../../../redux/api/api';
import CustomerOrderData from './VendorDashboardComponents/CustomerOrderData';

const VendorDashboard = ({ orgType }) => {
  //const orgId = useSelector((state) => state.app.preference.selectedOrgId);

  // Fetch queries with `enabled` only when orgId is available
  // const { data: orderThisMonthData } = useOrderThisMonthQuery(orgId, { enabled: !!orgId });
  // const { data: customerThisMonthData } = useNewCustomerThisMonthQuery(orgId, { enabled: !!orgId });
  // const { data: topOrderCustomersData } = useGetTopOrderingCustomersQuery(orgId, { enabled: !!orgId });
  // const { data: averageDailySalesData } = useAverageDailySalesQuery({ orgId }, { enabled: !!orgId });
  //const { data: totalDailySales } = useTotalDailySalesQuery({ orgId }, { enabled: !!orgId });

  // Memoized data processing for performance optimization
  // const averageDailySalesSeries = useMemo(() => {
  //   if (!averageDailySalesData) return [];
  //   return Object.entries(averageDailySalesData).map(([date, data]) => ({
  //     label: date,
  //     value: data?.averagePrice !== null ? data?.averagePrice : 0,
  //   }));
  // }, [averageDailySalesData]);

  // const totalDailySalesSeries = useMemo(() => {
  //   if (!totalDailySales) return [];
  //   return Object.entries(totalDailySales)
  //     .filter(([key]) => !['total', 'remainingTotal', 'remainingPercent', 'goal'].includes(key)) // Exclude non-date keys
  //     .map(([date, data]) => ({
  //       label: date,
  //       value: data?.totalSales !== null ? data?.totalSales : 0,
  //     }));
  // }, [totalDailySales]);

  if (orgType !== 'vendor') {
    return null; // Early return for non-vendor org types
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 5 }}>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        
        <Grid container item xs={12} md={6} spacing={3}>
          <Grid item md={12}>
          <CustomerOrderData/>
          </Grid>
          {/* <Grid item md={6}>
            {customerThisMonthData && topOrderCustomersData && (
              <NewCustomerThisMonth 
                data={customerThisMonthData} 
                topOrderCustomerData={topOrderCustomersData} 
              />
            )}
          </Grid> */}
          <Grid item md={12}>
              <AvergaeDailySales />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <SalesThisMonth />
        </Grid>
      </Grid>
    </Container>
  );
};

export default VendorDashboard;
